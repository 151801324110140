import React, { useEffect, useCallback, useState } from "react";
import { responseHero } from "../../utils/axiosInterceptor";
import { Template } from "../../models/templates.model";
import { useDispatch, useSelector } from 'react-redux';
import { selectMenuStates, set_template_menu } from "../../store/reducers/menuSlice";
import { REMOVE_SELECTED_USER } from "../../store/reducers/selectedUserSlice";
import { SET_TEMPLATES, selectNavStates } from "../../store/reducers/navSlice";
import { RefreshIcon } from "../../styles/assets/svgs/RefreshIcon";
import { useNavigate } from "react-router";
import { REMOVE_SELECTED_ORG } from "../../store/reducers/selectedOrgSlice";

export default function ContractTypeList() {
    const [templates, setTemplates] = useState<Template[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const dispatch = useDispatch();
    const menu = useSelector(selectMenuStates);
    const nav = useSelector(selectNavStates);
    const navigate = useNavigate();

    const getTemplates = useCallback(async () => {
        setLoading(true);
        dispatch(SET_TEMPLATES([]));
        try {
            const response = await responseHero.get(`${process.env.REACT_APP_API_URL}/templates`);
            setTemplates(response.data.reverse());
            dispatch(SET_TEMPLATES(response.data));
        } catch (error: any) {
            console.error(error.response?.data || error);
        } finally {
            setLoading(false);
        }
    }, [dispatch]);

    useEffect(() => {
        if (nav.templates.length === 0) {
            getTemplates();
        }
    }, [getTemplates, nav.templates.length]);

    return (
        <nav className="bg-dark-blue border-t-2 shadow-gray-700 shadow-lg py-4 mb-5 rounded">
            <div className="max-w-screen-xl mx-auto px-4">
                <div className="flex items-center justify-between">
                    <div className="flex gap-4 items-center">
                        <button onClick={getTemplates} className="p-2 rounded-lg bg-gray-800 hover:bg-gray-700 transition">
                            <RefreshIcon color="#FFFFFF" />
                        </button>
                        <p onClick={() => navigate('/envelopes')} className="text-gray-300 text-md cursor-pointer hover:text-white">Előzmények</p>
                    </div>
                    {loading && <p className="text-cream">Betöltés...</p>}
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4">
                    {nav?.templates?.map((template, index) => (
                        <div
                            key={index}
                            className={`p-4 text-center border cursor-pointer transition transform hover:scale-105 rounded ${menu.templateMenu === template ? 'bg-brown text-white shadow-md' : 'bg-gray-800 text-white'}`}
                            onClick={() => {
                                dispatch(REMOVE_SELECTED_USER());
                                dispatch(REMOVE_SELECTED_ORG());
                                dispatch(set_template_menu({ templateMenu: template }));
                            }}
                        >
                            {template.name}
                        </div>
                    ))}
                </div>
            </div>
        </nav>
    );
}
