import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { remove, selectUser } from "../store/reducers/userSlice";

interface SidebarProps {
  isOpen: boolean;
  toggleSidebar: () => void;
  setActiveSystem: (systemId: number | null) => void;
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen, toggleSidebar, setActiveSystem }) => {
  const [openMenus, setOpenMenus] = useState<{ [key: string]: boolean }>({});

  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  

  const logOut = () => {
    dispatch(remove());

    navigate('/'); // set to home
    navigate(0); // refresh
  }

  const toggleMenu = (menu: string) => {
    setOpenMenus((prev) => ({ ...prev, [menu]: !prev[menu] }));
  };

  return (
    <div
      className={`h-screen bg-dark-blue text-white w-64 transition-all duration-300 ${
        isOpen ? "translate-x-0" : "-translate-x-64"
      }`}
    >
      <nav className="p-4 overflow-auto h-[calc(100vh-50px)]">
        <div className="flex flex-row space-x-2 justify-center items-center mb-5">
            <img width={100} draggable={false} src={'./logo.png'}/>
            <div>
                <h1 className="text-xl font-semibold text-cream text-center">HDC ADMIN</h1>
            </div>
        </div>
        
        <ul className="space-y-4">
          <li><Link to="/" className="block hover:text-cream" onClick={() => setActiveSystem(null)}>Főoldal</Link></li>
          

          {/* Newsletter menü */}
          <li>
            <button onClick={() => { toggleMenu("newsletter"); setActiveSystem(null); }} className="w-full text-left hover:text-cream">
              Hírlevél {openMenus["newsletter"] ? "▼" : "▶"}
            </button>
            {openMenus["newsletter"] && (
              <ul className="ml-4 space-y-2">
                <li><Link onClick={() => setActiveSystem(null)} to="/newsletter" className="block hover:text-cream">Hírlevél - Küldés</Link></li>
                <li><Link onClick={() => setActiveSystem(null)} to="/newsletterlist" className="block hover:text-cream">Hírlevél - Levelezőlista</Link></li>
              </ul>
            )}
          </li>

          <li><Link to="/newsmanager" className="block hover:text-cream" onClick={() => setActiveSystem(null)}>Videók</Link></li>

          {/* D.A.I.SY menü */}
          <li>
            <button onClick={() => { toggleMenu("daisy"); setActiveSystem(null); }} className="w-full text-left hover:text-cream">
              D.A.I.SY {openMenus["daisy"] ? "▼" : "▶"}
            </button>
            {openMenus["daisy"] && (
              <ul className="ml-4 space-y-2">
                <li><Link onClick={() => setActiveSystem(1)} to="/daisy" className="block hover:text-cream">D.A.I.SY - Főoldal</Link></li>
                
                <li><Link onClick={() => setActiveSystem(1)} to="/payouts" className="block hover:text-cream">D.A.I.SY - Zárás</Link></li>

                <li><Link onClick={() => setActiveSystem(null)} to="/totalpayouts" className="block hover:text-cream">D.A.I.SY - Zárás - Összefoglaló</Link></li>
                
              </ul>
            )}
          </li>

          {/* Human D.C menü */}
          <li>
            <button onClick={() => { toggleMenu("humandc"); setActiveSystem(null); }} className="w-full text-left hover:text-cream">
              Human D.C {openMenus["humandc"] ? "▼" : "▶"}
            </button>
            {openMenus["humandc"] && (
              <ul className="ml-4 space-y-2">
                <li><Link onClick={() => setActiveSystem(2)} to="/hpm" className="block hover:text-cream">Human D.C.</Link></li>
                <li><Link onClick={() => setActiveSystem(4)} to="/altcoin" className="block hover:text-cream">Human D.C - Altcoin</Link></li>
                <li><Link onClick={() => setActiveSystem(2)} to="/leverage" className="block hover:text-cream">Tőkeáttétes kereskedés</Link></li>
                <li><Link onClick={() => setActiveSystem(null)} to="/minedtrading" className="block hover:text-cream">Bányászott Bitcoin kereskedés</Link></li>
                <li><Link onClick={() => setActiveSystem(null)} to="/protokol" className="block hover:text-cream">Human D.C - Protokol</Link></li>
              </ul>
            )}
          </li>

          <li>
            <button onClick={() => { toggleMenu("mining"); setActiveSystem(null); }} className="w-full text-left hover:text-cream">
              Bányagépek {openMenus["mining"] ? "▼" : "▶"}
            </button>
            {openMenus["mining"] && (
              <ul className="ml-4 space-y-2">
                <li><Link onClick={() => setActiveSystem(3)} to="/miningmachines" className="block hover:text-cream">Bányagépek - Főoldal</Link></li>
                <li><Link onClick={() => setActiveSystem(null)} to="/allmachine" className="block hover:text-cream">Összes bányagép listája</Link></li>
                <li><Link onClick={() => setActiveSystem(null)} to="/miningmachinesdaily" className="block hover:text-cream">Bányagéptípusok - Napi értékek</Link></li>
                <li><Link onClick={() => setActiveSystem(null)} to="/miningbeneficiaries" className="block hover:text-cream">Bányagép - Kedvezményezettek</Link></li>
              </ul>
            )}
          </li>

          <li><Link to="/group-manager" className="block hover:text-cream" onClick={() => setActiveSystem(null)}>Csoportkezelő</Link></li>
          <li><Link to="/depositregister" className="block hover:text-cream" onClick={() => setActiveSystem(null)}>Befizetések</Link></li>
          <li><Link to="/contracts" className="block hover:text-cream" onClick={() => setActiveSystem(null)}>Szerződéskezelő</Link></li>
          <li><Link to="/toplist" className="block hover:text-cream" onClick={() => setActiveSystem(null)}>Toplista</Link></li>
          <li><Link to="/manager" className="block hover:text-cream" onClick={() => setActiveSystem(null)}>Ügyfelek</Link></li>
          <li><Link to="/orgmanager" className="block hover:text-cream" onClick={() => setActiveSystem(null)}>Cégek</Link></li>
          <li><Link to="/company" className="block hover:text-cream" onClick={() => setActiveSystem(null)}>Cég</Link></li>
          <li><Link onClick={logOut} to="/login" className="block hover:text-cream">Kilépés ({user.userInfo.name})</Link></li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
