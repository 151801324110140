import React from "react";
import UserSelectModal from "../../components/humandc/modals/simulation/UserSelectModal";
import { DepositRegister } from "../../models/depositregister";
import { User } from "../../models/user.model";
import { getUserDepositsData } from "../../api/getUserDepositsData";
import { getUserSimsData, Sims } from "../../api/getUserSimsData";
import { useNavigate } from "react-router";

export default function HumanProtokol () {

    const [showUserModal, setShowUserModal] = React.useState(false);
    const [userDeposits, setUserDeposits] = React.useState<DepositRegister[]>([]);
    const [selectedDeposit, setSelectedDeposit] = React.useState<DepositRegister | null>(null);
    const [selectedUser, setSelectedUser] = React.useState<User | null>(null);
    const [simData, setSimData] = React.useState<Sims>();
    const navigate = useNavigate();

    React.useEffect(() => {
        if (!selectedUser) return;
        
        const fetchSimData = async () => {
            try {
            const res = await getUserSimsData(selectedUser?.user_id);
            setSimData(res)
            } catch (error) {
            console.error("Hiba az API hívásban:", error);
            }
        };
        
        fetchSimData();
    }, [selectedUser]);  

    const handleUserSelect = async (user: User) => {
        setSelectedUser(user);
        setShowUserModal(false);
      
        const result = await getUserDepositsData(user.user_id);
        console.log(JSON.stringify(result, null, 2));
        setUserDeposits(result);
      
        // Set first deposit as default if available
        if (result.length > 0) {
          setSelectedDeposit(result[0]);
        } else {
          setSelectedDeposit(null);
        }
      };

    return (
    <div className="bg-gray-900 p-6 rounded-lg">

      <UserSelectModal
        title="Ügyfél hozzárendelése"
        showModal={showUserModal}
        closeModal={() => setShowUserModal(false)}
        onUserSelect={handleUserSelect}
      />

    <div className="flex justify-between items-center mb-4">
        {/* New User Button (Left) */}
        {/* User Selection & Deposit Selector */}
        <div className="flex items-center gap-4">
          {/* New User Button */}
          <button
            onClick={() => setShowUserModal(true)}
            className="bg-blue-500 text-white px-4 py-2 rounded-lg shadow-md hover:bg-blue-600 transition"
          >
            ➕ Ügyfél kiválasztása
          </button>
        </div>

        {/* Selected User (Right) */}
        {selectedUser ? (
            <div className="text-white text-sm flex items-center">
            <span className="font-semibold">Jelenlegi Ügyfél:</span>
            <span className="ml-2">{selectedUser.name} ({selectedUser.email})</span>
            </div>
        ) : (
            <div className="w-[200px]"></div> // Placeholder width to maintain alignment
        )}
        </div>

        {simData && (
        <>
        <div className="grid grid-cols-3 gap-4 mt-6">
          <div className="bg-gray-200 p-4 rounded-lg shadow-md text-center">
            <h3 className="text-md font-semibold">Befektetett összeg</h3>
            <p className="text-2xl mt-2">€{simData.totalInvested}</p>
          </div>
          <div className="bg-gray-200 p-4 rounded-lg shadow-md text-center">
            <h3 className="text-md font-semibold">Összérték</h3>
            <p className="text-2xl mt-2">€{simData.totalValueEUR}</p>
          </div>
          <div className={`bg-gray-200 p-4 rounded-lg shadow-md text-center`}>
            <h3 className={`text-md font-semibold`}>Változás</h3>
            <p className={`text-2xl ${parseFloat(simData.increasePercentage) >= 0 ? 'text-green-700' : 'text-red-700'} text-2xl mt-2`}>{simData.increasePercentage}%</p>
          </div>
        </div>
        <div className="mt-6">
            <button
                onClick={() => navigate('/protokoldetails', { state: { selectedUser } })}
                className="w-full bg-emerald-600 text-white px-4 py-2 rounded-lg shadow-md hover:bg-emerald-500 transition"
            >
            Részletes kimutatás
            </button>
        </div>
        </>
      )}
    </div>
    )
}
