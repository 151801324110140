import Decimal from "decimal.js";
import { BinanceRates } from "./binancerates.model";
import { AssetPurchase } from "./assetpurchase.model";
import { DepositRegister } from "./depositregister";

// pre

export type PreparedData = {
    depositRegisterData: Pick<DepositRegister, "id" | "amount_in_trade" | "liquidity" | "date" | "deduction_all" | "deduction_alt" | "cost_deduction" | "fee"> | null;
    assetPurchasesData: Pick<AssetPurchase, "assetId" | "quantity" | "amount" | "date" | "exchange_rate">[];
    binanceRates: Map<number, Omit<BinanceRates, "id" | "asset">[]>;
}

// sim

export type SimulationData = {
    currentState: {
        depositRegisterId: number;
        date: Date;
        liquidity: Decimal;
        quantity: Decimal;
        mining: Decimal;  // Bányagép energia
        gold: Decimal;    // Arany tartalék
        altcoin: Decimal; // Altcoin befektetés
    };
    buyOperations: Transaction[];
    sellOperations: Transaction[];
}

export const RecordType = {
    SIMPLE_TRANSACTION: 0,
    INVESTMENT_TRANSACTION: 1,
    FROM_GOLD: 2,
};

export type Transaction = {
    date: Date;
    amount: Decimal;
    quantity: Decimal;
    binanceRateRecord: Omit<BinanceRates, "id" | "asset" | "assetId">;
    recordType: number;
    executionPrice: Decimal;
};

// sum

export type SummarizationData = {
    liquidity: {
        previous: Decimal;
        current: Decimal;
    };
    quantity: {
        previous: Decimal;
        current: Decimal;
    };
    exchangeRate: {
        previous: Decimal;
        current: Decimal;
    };
    todaysPrice: {
        previous: Decimal;
        current: Decimal;
    };
    totalValue: {
        previous: Decimal;
        current: Decimal;
    };
    attachments: {
        mining: Decimal;
        gold: Decimal;
        altcoin: Decimal;
    }
};

// merged response

export type SimRes = {
    preparedData: PreparedData;
    simulationData: SimulationData;
    summarization: SummarizationData;
}