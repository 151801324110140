import { responseHero } from "../utils/axiosInterceptor";

export type Sims = {
    totalInvested: string;
	totalValueEUR: string;
	increasePercentage: string;
};

export const getUserSimsData = (user_id: number): Promise<Sims> => {
    return new Promise<Sims>((resolve, reject) => {
        if(!user_id) return;

        responseHero.get(`${process.env.REACT_APP_API_URL}/sims/${user_id}`).then((response) => {
            resolve(response.data as Sims);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};