import { SimRes, SimulationData } from "../models/simulationdata.model";
import { responseHero } from "../utils/axiosInterceptor";

export const getSimulationData = (depositRegisterId: number, assetId: number): Promise<SimRes> => {
    return new Promise<SimRes>((resolve, reject) => {
        if(!assetId || !depositRegisterId) return;

        responseHero.get(`${process.env.REACT_APP_API_URL}/sim/${depositRegisterId}/${assetId}`).then((response) => {
            resolve(response.data as SimRes);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};